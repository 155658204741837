import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { ChangeEvent, SyntheticEvent, useRef, useState } from "react";
import shared from "../../shared/shared.module.css";
import styles from "./styles.module.css";
import img from "./img.png";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import iTitle from "./title.png";

export const Kontakt = () => {
  const [email, setEmail] = useState<string>("");
  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);
  const [message, setMessage] = useState<string>("");
  const onMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(event.target.value);
  const [RCToken, setRCToken] = useState<string | null>(null);
  const onRCTokenChange = (token: string | null) => setRCToken(token);

  const recaptchaRef = useRef<any>();

  const onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    if (!email || !message || !RCToken) {
      return;
    }

    axios
      .post("/mail-gaming-heroes.php", {
        email,
        message,
        token: RCToken,
      })
      .then(() => {
        alert("Vaše zpráva byla odeslána.");
        setEmail("");
        setMessage("");
        setRCToken("");
        recaptchaRef.current.reset();
      })
      .catch(() => {
        alert("Něco se nepovedlo. Zkuste to prosím znovu později.");
      });
  };

  return (
    <section id="kontakt" className={shared.section}>
      <div className="grid">
        <div className="col-12 xl:col-4">
          <img src={iTitle} alt="Kontakt" />
          <h3>Chcete mít také superhrdiny ve svém týmu?</h3>
          <img src={img} alt="Kontakt" />
        </div>

        <div className="col-12 xl:col-8">
          <form onSubmit={onSubmit}>
            <div className="p-fluid">
              <div className="field pt-3">
                <div className="p-float-label">
                  <InputText
                    className={styles.field}
                    value={email}
                    onChange={onEmailChange}
                  />
                  <label>Váš e-mail</label>
                </div>
                <small>
                  Zkontrolujte si, zda jste svůj e-mail napsali správně
                </small>
              </div>

              <div className="field pt-3">
                <div className="p-float-label">
                  <InputTextarea
                    rows={5}
                    className={styles.field}
                    value={message}
                    onChange={onMessageChange}
                  />
                  <label>Vaše zpráva</label>
                </div>
                <small>
                  Odesláním souhlasíte se zpracováním osobních údajů
                </small>
              </div>

              <div className="field">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={"6LcT6kwnAAAAAAZJqijPvOq4IEPJL4vkA_snFKsZ"}
                  onChange={onRCTokenChange}
                />
              </div>
            </div>

            <Button
              label="Odeslat"
              className={styles.button}
              disabled={!email || !message || !RCToken}
            />
          </form>
        </div>
      </div>
    </section>
  );
};
