import styles from "./styles.module.css";
import shared from "../../shared/shared.module.css";
import { classNames } from "primereact/utils";

export const Footer = () => {
  return (
    <footer className={`${shared.section} ${styles.footer}`}>
      <div className="grid">
        <div className="col-12">
          <div className="flex gap-1 justify-content-end">
            <a
              href="https://www.facebook.com/profile.php?id=61551868748322"
              target="_blank"
              rel="noreferrer"
              className={classNames([styles.socialLink, styles.socialLinkFB])}
            >
              Facebook
            </a>

            <a
              href="https://www.linkedin.com/company/gamingheroes/"
              target="_blank"
              rel="noreferrer"
              className={classNames([styles.socialLink, styles.socialLinkLin])}
            >
              LinkedIn
            </a>

            <a
              href="https://twitter.com/goodsailors"
              target="_blank"
              rel="noreferrer"
              className={classNames([styles.socialLink, styles.socialLinkX])}
            >
              X
            </a>

            {/* <a
              href="https://instagram.com"
              target="_blank"
              rel="noreferrer"
              className={classNames([styles.socialLink, styles.socialLinkIn])}
            >
              Instagram
            </a> */}
          </div>
        </div>

        <div className="col-12 text-sm">
          &copy; Gaming Heroes. Všechna práva vyhrazena. Powered by Goodsailors.
          <br />
          Ilustrace ondrejondrej.art
        </div>
      </div>
    </footer>
  );
};
