import shared from "../../shared/shared.module.css";
import img from "./img.png";
import warhorseLogo from "./warhorse-logo.png";
import iTitle from "./title.png";

export const Spoluprace = () => {
  return (
    <section id="spolupracujeme" className={shared.section}>
      <div className="grid">
        <div className="col-12">
          <img src={iTitle} alt="Spolupráce" />
          <img src={img} alt="Spolupráce" />
        </div>

        <div className="col-12 text-center">
          <img src={warhorseLogo} alt="Warhorse logo" style={{ width: 500 }} />
        </div>
      </div>
    </section>
  );
};
