import { useEffect, useRef, useState } from "react";
import { Carousel } from "primereact/carousel";
import shared from "../../shared/shared.module.css";
import iPoruchaSluchu from "./porucha-sluchu.png";
import iAutismus from "./autismus.png";
import iDyslexie from "./dyslexie.png";
import iADHD from "./adhd.png";
import iEpilepsie from "./epilepsie.png";
import iHypermobilita from "./hypermobilita.png";
import iOCD from "./ocd.png";
import iTinnitus from "./tinitus.png";
import iTitle1 from "./title.png";
import iTitle2 from "./title2.png";
import styles from "./styles.module.css";
import { NavLink } from "react-router-dom";

export const Superschopnosti = () => {
  const tItem = (item: Item) => (
    <div className="md:flex align-items-center">
      <div>
        <h3 className="font">{item.title}</h3>
        <p className="font">{item.text}</p>
      </div>
      <div className="text-center">
        <img src={item.img} alt={item.title} />
      </div>
    </div>
  );

  const [items] = useState<Item[]>([
    {
      title: "Porucha sluchu",
      text: "Osoby se sluchovým postižením mají často zvýšené vnímání neverbální komunikace. Mohou pomoci s návrhem gest a animací postav ve hrách.",
      img: iPoruchaSluchu,
      visible: true,
    },
    {
      title: "Autismus",
      text: "Lidé s autismem často vykazují vysokou úroveň soustředění a detailní znalost specifických témat. Mohou být skvělými analytiky, vývojáři nebo testery her.",
      img: iAutismus,
      visible: true,
    },
    {
      title: "Dyslexie",
      text: "Dyslektici mají často silné vizuální myšlení a kreativitu. Mohou excelovat v grafickém designu, vizuálním storytellingu nebo architektuře her.",
      img: iDyslexie,
      visible: true,
    },
    {
      title: "ADHD",
      text: "Lidé s ADHD mohou mít vynikající multitaskingové schopnosti a pružnost při změnách. Mohou se stát skvělými projektovými manažery nebo koordinátory týmů.",
      img: iADHD,
      visible: true,
    },
    {
      title: "Epilepsie",
      text: "Osoby s epilepsií mohou být citlivé na vizuální efekty a rychle identifikovat problémy, které by mohly způsobit záchvaty u jiných hráčů. Mohou být užiteční při testování a optimalizaci vizuálních aspektů her.",
      img: iEpilepsie,
      visible: true,
    },
    {
      title: "Hypermobilita",
      text: "Jedinci s hypermobilitou mohou mít zvýšené schopnosti v oblasti pohybu a flexibility. Mohou excelovat jako motion capture umělci nebo odborníci na animaci.",
      img: iHypermobilita,
      visible: true,
    },
    {
      title: "OCD",
      text: "",
      img: iOCD,
      visible: false,
    },
    {
      title: "Tinnitus",
      text: "Lidé s tinnitem mohou mít větší citlivost na zvuk a zvukový design. Mohou pracovat jako zvukoví inženýři nebo hudební skladatelé pro hry.",
      img: iTinnitus,
      visible: true,
    },
  ]);

  const carouselRef = useRef<Carousel | null>(null);
  useEffect(() => {
    carouselRef.current?.startAutoplay();
  }, []);

  return (
    <section id="superschopnosti" className={shared.section}>
      <div className="grid">
        <div className="col-12">
          <img src={iTitle1} alt="Jak se hendikep stává superschopností" />
          <Carousel
            ref={carouselRef}
            numVisible={1}
            numScroll={1}
            value={items.filter((item) => item.visible)}
            itemTemplate={tItem}
            circular={true}
            autoplayInterval={5000}
            showNavigators={true}
          />
        </div>

        <div className="col-12">
          <img src={iTitle2} alt="Co přinášíme?" />
          <ul>
            <li>
              Unikátní perspektiva: Naši superhrdinové přinášejí jedinečný
              pohled na testování her díky svým specifickým schopnostem a
              zkušenostem.
            </li>
            <li>
              Přístupnost a inkluzivita: Spolupráce zajišťuje, že hry budou
              navrženy pro co nejširší spektrum hráčů, včetně těch se zdravotním
              postižením.
            </li>
            <li>
              Detailní testování: Díky našim superhrdinům provádíme důkladnější
              testování, což vede k vyšší kvalitě her.
            </li>
            <li>
              Posilování image: Spolupráce s Gaming Heroes ukazuje vaši sociální
              odpovědnost a podporu diverzity ve hrách.
            </li>
            <li>
              Inovace a kreativita: Naše studio podněcuje inovace v herním
              průmyslu a přináší nové nápady pro konkurenční prostředí.
            </li>
          </ul>
        </div>

        <div className="col-12">
          <div className="text-center">
            <NavLink to="#kontakt" className={styles.button}>
              Spolupracujte s Gaming Heroes!
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

interface Item {
  title: string;
  text: string;
  img: string;
  visible: boolean;
}
