import shared from "../../shared/shared.module.css";
import iTitle from "./title.png";

export const NaseVize = () => {
  return (
    <section id="nase-vize" className={shared.section}>
      <div className="grid">
        <div className="col-12">
          <img src={iTitle} alt="Naše vize" />
          <p>
            Naše vize spočívá v transformaci zdravotních omezení v superhrdinské
            schopnosti pro testování a vývoj počítačových her. Věříme v sílu
            diverzity a inkluzivity, která nám umožňuje poskytnout jedinečný
            pohled na herní průmysl a zlepšovat přístupnost her pro všechny
            hráče.
          </p>
          <p>
            Naše studio se zaměřuje na spolupráci s talentovanými jedinci se
            zdravotním postižením, jejichž schopnosti se stávají cennými
            nástroji pro naše partnery. Společně s vámi překonáváme překážky a
            posouváme hranice možného ve světě videoher, kde každý může být
            superhrdinou.
          </p>
        </div>
      </div>
    </section>
  );
};
