import { Menubar } from "primereact/menubar";
import { NavLink, useLocation } from "react-router-dom";
import { MenuItem, MenuItemOptions } from "primereact/menuitem";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import logo from "./logo.png";

export const Navbar = () => {
  const { hash } = useLocation();
  const [activeHash, setActiveHash] = useState<string>("nase-vize");

  const start = (
    <img src={logo} alt="Gaming Heores logo" className={styles.logo} />
  );

  const tMenuItem = (item: MenuItem, options: MenuItemOptions) => {
    return (
      <NavLink
        to={`#${item.url}`}
        className={`${options.className} ${
          activeHash === item.url ? styles.linkActive : ""
        }`}
      >
        {item.label}
      </NavLink>
    );
  };

  const model: MenuItem[] = [
    { label: "Naše vize", url: "nase-vize", template: tMenuItem },
    { label: "Superschopnosti", url: "superschopnosti", template: tMenuItem },
    { label: "O nás", url: "o-nas", template: tMenuItem },
    { label: "O projektu", url: "o-projektu", template: tMenuItem },
    { label: "Spolupracujeme", url: "spolupracujeme", template: tMenuItem },
    { label: "Kontakt", url: "kontakt", template: tMenuItem },
  ];

  useEffect(() => {
    const newHash = hash.replace("#", "");
    setActiveHash(newHash || "nase-vize");
    const el = document.getElementById(newHash);

    el?.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });
  }, [hash]);

  return (
    <div className={styles.navbar}>
      <Menubar start={start} model={model} className={styles.menubar} />
    </div>
  );
};
