import shared from "../../shared/shared.module.css";
import img1 from "./img1.png";
import img2 from "./img2.png";
import GSLogo from "./gs-logo.svg";
import iTitle from "./title.png";

export const ONas = () => {
  return (
    <section id="o-nas" className={shared.section}>
      <div className="grid">
        <div className="col-12 lg:col-2">
          <img src={img1} alt="O nás" />
        </div>

        <div className="col-12 lg:col-2">
          <img src={img2} alt="O nás" />
        </div>

        <div className="col-12 lg:col-8">
          <img src={iTitle} alt="O nás" />
          <p>
            Jako součást Good Sailors, společnosti s jednadvacetiletou historií
            v oblasti IT konzultací a vývoje aplikací, jsme založili toto
            jedinečné studio, aby vytvořilo prostor pro rozvoj talentů se
            zdravotním postižením a jejich začlenění do "ostrého byznysu".
          </p>
          <p>
            Díky našim zkušenostem a unikátnímu přístupu vedení projektů jsme
            schopni nabízet služby, které skutečně dávají smysl a přinášejí
            hodnotu pro naše klienty, zatímco aktivně přispíváme k větší
            inkluzivitě a diverzitě ve světě videoher.
          </p>

          <img src={GSLogo} alt="Goodsailors logo" width={100} />
        </div>
      </div>
    </section>
  );
};
