import styles from "./styles.module.css";
import iLogo from "./logo.png";
import iAnimace1 from "./animace-0-bg.png";
import iAnimace2 from "./animace-1.png";
import iAnimace3 from "./animace-2.png";

export const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.animation}>
        <img src={iAnimace1} alt="Pozadí" />
        <img src={iAnimace2} className={styles.animationImg} alt="Obrázek 1" />
        <img src={iAnimace3} className={styles.animationImg} alt="Obrázek 2" />
      </div>
      <img src={iLogo} className={styles.iLogo} alt="Gaming Heroes logo" />
    </header>
  );
};
