import { toastContext } from "./context/toast";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { Navbar } from "./component/navbar";
import { Header } from "./component/header";
import { NaseVize } from "./component/nase-vize";
import { OProjektu } from "./component/o-projektu";
import { ONas } from "./component/o-nas";
import { Superschopnosti } from "./component/superschopnosti";
import { Kontakt } from "./component/kontakt";
import { Spoluprace } from "./component/spoluprace";
import { Footer } from "./component/footer";

export default function App() {
  const toastRef = useRef<Toast>(null);

  return (
    <toastContext.Provider value={{ ref: toastRef }}>
      <Toast ref={toastRef} />

      <div className="container">
        <div className="content">
          <Navbar />
          <Header />
          <NaseVize />
          <Superschopnosti />
          <ONas />
          <OProjektu />
          <Kontakt />
          <Spoluprace />
          <Footer />
        </div>
      </div>
    </toastContext.Provider>
  );
}
