import eu from "../../shared/eu.png";
import shared from "../../shared/shared.module.css";
import img from "./img.png";
import iTitle from "./title.png";
import iUKLogo from "./uk-logo.png";

export const OProjektu = () => {
  return (
    <section id="o-projektu" className={shared.section}>
      <div className="grid">
        <div className="col-12 lg:col-8">
          <img src={iTitle} alt="O projektu" />
          <p>
            GOODSAILORS realizuje projekt GAMING HEROES, r.č.
            CZ.03.02.01/00/22_024/0001356, který je podpořen z Evropské unie,
            Evropského sociálního fondu, Operačního programu Zaměstnanost+.
          </p>

          <img src={eu} alt="Spolufinancováno EU" style={{ width: 500 }} />

          <p>
            Cílem projektu je produkční rozšíření integračního sociálního
            podniku Good Sailors s.r.o. Novou podnikatelskou aktivitou
            sociálního podniku je prodej služeb testování herních aplikací a s
            ním spojené vytvoření 4 nových pracovních míst pro zdravotně
            znevýhodněné osoby.
          </p>

          <p>
            Doba realizace projektu
            <br />
            01.03.2023 - 28.02.2025
          </p>

          <p>V rámci projektu bude realizováno 5 klíčových aktivit</p>

          <ul>
            <li>Vznik nových pracovních míst pro znevýhodněné skupiny.</li>
            <li>
              Vzdělávání zaměstnanců a individuální rozvoj zaměstnanců
              sociálního podniku.
            </li>
            <li>Marketing GAMING HEROES.</li>
            <li>Provoz sociálního podniku.</li>
            <li>Obchodní činnost a rozvoj příležitostí.</li>
          </ul>

          <h3 className="font mt-6">Podpořili nás</h3>
          <img src={iUKLogo} alt="Logo ústeckého kraje" width="350" />
        </div>

        <div className="col-12 lg:col-4">
          <img src={img} alt="O projektu" />
        </div>
      </div>
    </section>
  );
};
